import React from 'react';
import { ResultListItem } from './results-list-item-component';
import { GqlResultsPageTestKitFragmentFragment, Maybe } from '@when-fertility/shared/gql/graphql';

export const PatientReports = ({ testKits }: { testKits: Maybe<Array<GqlResultsPageTestKitFragmentFragment>> | undefined }) => (
  <div className="w-full space-y-8">
    <div className="mt-6">
      <div className="text-xs font-normal text-left">
        <div className="hidden sm:flex gap-2 bg-silver-100 px-10 py-4 text-charcoal-100 rounded-t-lg">
          <div className="w-3/12 text-base">Test Kit ID</div>
          <div className="w-2/12 text-base">Sample Collected</div>
          <div className="w-2/12 text-base">Accession ID</div>
          <div className="w-2/12 text-base">Result</div>
          <div className="w-3/12 text-base text-center">Status</div>
        </div>
        {!testKits?.length && (
          <div className="flex sm:bg-white justify-center px-10 py-4 text-charcoal-100 w-full italic rounded-b-lg">No reports</div>
        )}
        {testKits &&
          Boolean(testKits.length) &&
          testKits.map((testKit, index) => {
            return <ResultListItem testKit={testKit} key={index} />;
          })}
      </div>
    </div>
  </div>
);
