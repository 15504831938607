import React from 'react';
import { Button } from '@when-fertility/shared/domain/common';
import { Link } from 'react-router-dom';
import { useViewQuestionnareButtonGetProfileFromS3Mutation } from '@when-fertility/shared/gql/graphql';
import gql from 'graphql-tag';
import { Modal } from '@when-fertility/shared/domain/common';
import { ApolloError } from '@apollo/client';
import { NURSE_ROUTES } from '../../nurse.routes';

type Props = {
  testKitId: string;
};

export const ViewQuestionnaireButton = ({ testKitId }: Props) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [error, setError] = React.useState<ApolloError | null>(null);
  const [getProfileFromS3, { data, loading }] = useViewQuestionnareButtonGetProfileFromS3Mutation();
  if (!testKitId) {
    return null;
  }

  return (
    <>
      <Button
        className="text-white mb-2"
        size="small"
        isDisabled={loading}
        onClick={() =>
          getProfileFromS3({
            variables: { input: { id: testKitId } },
            onCompleted: () => {
              setIsModalOpen(true);
            },
            onError: (anError) => {
              setError(anError);
              setIsModalOpen(true);
            },
          })
        }
      >
        {loading ? 'Loading...' : 'Download Questionnaire'}
      </Button>
      <Modal isOpen={isModalOpen} setIsOpen={setIsModalOpen} widthClasses="w-full lg:w-2/3">
        {!data && error && (
          <div className="flex flex-col justify-center items-center">
            <div className="text-xl mb-4">Sorry there was an error loading this profile.</div>
            <div>{error.message}</div>
          </div>
        )}
        <pre className="whitespace-pre-wrap">
          {JSON.stringify(
            data?.getProfileFromS3?.items,
            (key, value) => {
              if (key === '__typename') return undefined;
              return value;
            },
            2
          )}
        </pre>
      </Modal>
    </>
  );
};

ViewQuestionnaireButton.mutation = gql`
  mutation ViewQuestionnareButtonGetProfileFromS3($input: GetProfileFromS3Input!) {
    getProfileFromS3(input: $input) {
      items {
        key
        value
        values
        comment
      }
    }
  }
`;
